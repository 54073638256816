<template>
  <div class="px-12 py-16 my-4">
    <h1 class="page-title mb-7">SETTINGS</h1>

    <div class="page-main d-flex">
      <div class="page-main_options">
        <!-- change name -->
        <v-row
          class="mb-4"
          no-gutters>
          <v-btn
            elevation="0"
            color="offWhite"
            class="text-none d-flex justify-space-between px-5 py-8 rounded-xl"
            block
            :class="{ 'btn-active': this.option === 'name' }"
            @click="chooseOption('name')">
            <span class="btn-span brandGrey1--text">Change name</span>
            <v-icon color="primaryColor">mdi-arrow-right</v-icon>
          </v-btn>
        </v-row>
        <!-- change email address -->
        <v-row
          class="mb-4"
          no-gutters>
          <v-btn
            elevation="0"
            color="offWhite"
            class="text-none d-flex justify-space-between px-5 py-8 rounded-xl"
            block
            :class="{ 'btn-active': this.option === 'email' }"
            @click="chooseOption('email')">
            <span class="btn-span brandGrey1--text">Update email address</span>
            <v-icon color="primaryColor">mdi-arrow-right</v-icon>
          </v-btn>
        </v-row>
        <!-- account status -->
        <v-row
          class="mb-4"
          no-gutters>
          <v-btn
            elevation="0"
            color="offWhite"
            class="text-none d-flex justify-space-between px-5 py-8 rounded-xl"
            block>
            <span class="btn-span brandGrey1--text">Account status</span>
            <span class="light-green"
              ><span
                id="dot"
                class="d-inline-block mr-1"
                >&#x2022;</span
              >
              active</span
            >
          </v-btn>
        </v-row>
        <!-- invite link -->
        <v-row
          class="mb-4"
          style="width: 100%"
          no-gutters>
          <v-btn
            elevation="0"
            color="offWhite"
            class="text-none text-left d-flex justify-space-between px-5 py-8 rounded-xl"
            block>
            <div class="text btn-span">
              <p
                id="invite"
                :style="{ color: brandGrey2, fontSize: '.8rem' }">
                Invite your colleagues
              </p>
              <p class="font-weight-bold py-0">{{ trimLink(inviteLink) }}</p>
            </div>

            <v-btn
              text
              :disabled="isFetchingOrganization"
              id="copy"
              class="light-green pa-0"
              @click="copyInviteLink"
              >COPY</v-btn
            >
          </v-btn>
        </v-row>
        <!-- email preferences -->
        <v-row
          class="mb-4"
          no-gutters>
          <v-btn
            elevation="0"
            color="offWhite"
            class="text-none d-flex justify-space-between px-5 py-8 rounded-xl"
            :class="{ 'btn-active': this.option === 'email-preferences' }"
            @click="chooseOption('email-preferences')"
            block>
            <span class="btn-span brandGrey1--text">Email preferences</span>
            <v-icon color="primaryColor">mdi-arrow-right</v-icon>
          </v-btn>
        </v-row>
        <!-- help and FAQ -->
        <v-row
          class="mb-4"
          no-gutters>
          <v-btn
            elevation="0"
            color="offWhite"
            class="text-none d-flex justify-space-between px-5 py-8 rounded-xl"
            @click="openFAQs"
            block>
            <span class="btn-span brandGrey1--text">Help and FAQs</span>
            <v-icon color="primaryColor">mdi-arrow-right</v-icon>
          </v-btn>
        </v-row>
        <!-- terms and conditions -->
        <v-row
          class="mb-4"
          no-gutters>
          <v-btn
            elevation="0"
            color="offWhite"
            class="text-none d-flex justify-space-between px-5 py-8 rounded-xl"
            @click="openTermsAndConditions"
            block>
            <span class="btn-span brandGrey1--text">Terms and Conditions</span>
            <v-icon color="primaryColor">mdi-arrow-right</v-icon>
          </v-btn>
        </v-row>
        <!-- contact us -->
        <v-row
          class="mb-4"
          no-gutters>
          <v-btn
            elevation="0"
            color="primaryColor"
            outlined
            class="pa-6 btn-contact-us"
            rounded
            block
            :href="`mailto:${email}`">
            CONTACT US
          </v-btn>
        </v-row>
      </div>

      <div class="page-main_selected-option">
        <component :is="optionComponent" />
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import SettingsMixin from '@/views/profile/settings/settings/Settings.mixin.vue';

  export default {
    mixins: [SettingsMixin],
    data() {
      return {
        option: null,
      };
    },
    computed: {
      optionComponent() {
        switch (this.option) {
          case 'name':
            return () => import('@/views/profile/settings/change-name/ChangeName.desktop.vue');
          case 'email':
            return () => import('@/views/profile/settings/change-email/ChangeEmail.desktop.vue');
          case 'email-preferences':
            return () => import('@/views/profile/settings/email-preferences/EmailPreferences.desktop.vue');
          default:
            return this.noOptionComponent;
        }
      },
      noOptionComponent() {
        return Vue.component('no-option-component', {
          render(h) {
            return h('div', '');
          },
        });
      },
    },
    methods: {
      chooseOption(option) {
        this.option = option;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .page-title {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    color: var(--v-offBlack-base);
  }
  .page-main_options {
    margin-right: 100px;
  }
  .light-green {
    color: var(--v-primaryColor-base);
    background-color: transparent !important;

    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
  }
  #dot {
    transform: translateY(-3px) scale(3.5);
  }
  #invite {
    font-size: 0.8rem;
    transform: translateY(8px);
  }
  #copy {
    transform: translate(10px, 8px);
  }
  .btn-span {
    font-size: 16px;
    line-height: 20px;
  }
  ::v-deep .btn-active {
    background-color: var(--v-primaryColor-base) !important;
    .btn-span {
      font-weight: 600;
    }
    .v-btn__content {
      span,
      i {
        color: #ffffff !important;
      }
    }
  }
  .btn-contact-us {
    font-weight: 600;
  }
</style>
